<!--会员卡类型-->
<template>
    <div class="CardType" style="background-color: #f2f2f2; margin-top: 8px">
        <el-card shadow="never" style="margin-top: 8px">
            <el-form
                @submit.native.prevent
                :inline="true"
                style="text-align: right"
                v-model="form"
                size="small"
                label-width="85px"
            >
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="卡类名称">
                            <el-input placeholder="" v-model="form.search" />
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </el-card>
        <el-card shadow="never" style="margin-top: 8px; text-align: left">
            <el-button
                type="primary"
                @click="searchHandleQuery"
                size="small"
                v-if="hasPrivilege('menu.member.cardType.open')"
                >查询</el-button
            >
            <el-button
                type="primary"
                @click="handleExport"
                size="small"
                v-if="hasPrivilege('menu.member.cardType.export')"
                >导出</el-button
            >
            <span style="float: right">
                <el-button size="mini" @click="handlePrint">打印</el-button>
            </span>
        </el-card>
        <el-card shadow="never" style="margin-top: 8px">
            <el-table
                id="printMe"
                border
                stripe
                style="width: 100%"
                :data="tableData"
                size="mini"
                :highlight-current-row="true"
                max-height="440"
            >
                <el-table-column label="序号" width="60" type="index" />
                <el-table-column label="卡类名称" width="180" prop="name" />
                <el-table-column label="标识符" width="180" prop="flag" />
                <el-table-column label="创建人" width="180" prop="creator" />
                <el-table-column label="创建时间" width="180" prop="createTime" />
            </el-table>
        </el-card>
    </div>
</template>

<script>
import Util from 'js/Util';
import UrlUtils from 'js/UrlUtils';

export default {
    name: 'CardType',
    data() {
        return {
            form: {
                search: '',
                page: 1,
                limit: Util.Limit,
            },
            page: {
                total: 0,
                pageSizes: Util.PageSizes,
                PageStyle: Util.PageStyle,
            },
            tableData: [],
            url: {
                page: '/member/cardType/page',
            },
        };
    },
    mounted() {
        this.handleQuery();
    },
    methods: {
        searchHandleQuery() {
            this.form.page = 1;
            this.handleQuery();
        },
        handleQuery() {
            const _this = this;
            const _params = { params: _this.form };
            Util.queryTable(_this, _this.url.page, _params);
        },
        handleExport() {
            UrlUtils.Export(this, '会员卡类型', '/member/cardType/export', this.form);
        },
        handlePrint() {
            this.printHTML('printMe', this.$route.meta.title);
        },
    },
};
</script>

<style scoped>
.CardType .el-form-item {
    margin-bottom: 0;
}
</style>
